class CarouselTemplateProductIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('CarouselTemplateProduct before load');
	}

	afterLoad() {
		// console.log('CarouselTemplateProduct After load');
		this.$el = $(this);
		const isAuthorMode = this.$el.hasClass('author-mode');
		const slideContainer = this.$el.find('.bat-carousel-slides');

		// Convert values to bools and ints
		this.data.config.arrows = JSON.parse(this.data.config.arrows);
		this.data.config.autoplay = JSON.parse(this.data.config.autoplay);
		this.data.config.dots = JSON.parse(this.data.config.dots);
		this.data.config.infinite = JSON.parse(this.data.config.infinite);
		this.data.config.speed = this.data.config.speed
			? parseInt(this.data.config.speed, 10)
			: 300;

		// hide arrows if false
		if (!this.data.config.arrows) {
			this.$el.find('.slick-arrow').hide();
		}

		// No re-render updates
		$.map(this.$el.find('.bat'), (el) => {
			$(el).addClass('no-update');
		});

		const slickConfig = $.extend(
			{
				prevArrow: this.$el.find('.slick-prev'),
				nextArrow: this.$el.find('.slick-next'),
				rows: 0,
				slidesToShow: 4,
				slidesToScroll: 4,
				responsive: [
					{
						breakpoint: 1024,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							arrows: false,
							dots: true,
						},
					},
					{
						breakpoint: 577,
						settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							centerMode: false,
							arrows: false,
							dots: true,
						},
					},
				],
			},
			this.data.config
		);

		if (!isAuthorMode) {
			$(slideContainer).on('init', () => {
				$(slideContainer).addClass('loaded');
			});

			setTimeout(() => {
				$(slideContainer).slick(slickConfig);
			}, 500);
		}
	}

	beforeUpdate() {
		// console.log('CarouselTemplateProduct before update');
	}

	afterUpdate() {
		// console.log('CarouselTemplateProduct after update');
	}

	unload() {
		// console.log('CarouselTemplateProduct after unload');
	}
}

!customElements.get('bat-carousel-gloitproductcard') &&
	customElements.define('bat-carousel-gloitproductcard', CarouselTemplateProductIT);
